import React from "react"
import { CrowdCallContainer } from "../components/global/global";
import { ContactFormWrapper } from "../components/contact/contactFormWrapper";
import { Footer } from "../components/footer/footer";
import { NavBar } from "../components/navbar/navbar";

export default () => <CrowdCallContainer>
  <NavBar dark />
  <br />
  <ContactFormWrapper />

  <Footer />

</CrowdCallContainer>
