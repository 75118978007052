import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ContactForm } from './contactForm';

export const ContactFormWrapper = () => <Row id="contact" className="align-items-center">
  <Col xs={{ span: 10, offset: 1 }} md={{span: 5, offset: 1}}>
    <h1 css={{ fontSize: "48px" }}>Get Started Today</h1>
    <p css={{ fontSize: "20px" }}>It is time to find a better phone solution for your volunteers.</p>
    <p css={{ fontSize: "20px" }}>We’ll be in touch about how CrowdCall can help your campaign within 24 hours.</p>
    <br />
    <hr />
    <br />
    <ContactForm />
  </Col>
  <Col className="d-none d-lg-block" lg={{span: 4, offset: 2}}>
    <img alt="A flying business man" src="/images/get-in-touch.svg" css={{ width: "100%" }} />
  </Col>
</Row>