import React from "react"

import styled from '@emotion/styled'
import { PatternDiv } from '../pattern-div/patternDiv';
import { Link } from "gatsby";

const FooterDiv = styled(PatternDiv)`
  text-align: center;
  padding: 50px 0;
  margin: 50px 0px 0 0;
`


export const Footer = () => <FooterDiv>
  <div css={{ margin: '0 auto', width: "200px" }}>
    <Link to="/">
      <img alt="CrowdCall logo" css={{width: '100%'}} src="/images/logo/logo-white.svg" />
    </Link>
  </div>
</FooterDiv>